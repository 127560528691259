.ep {
    background-color: #fc8403;
    padding: 5px;
    
    h1, h2, h3, h4, h5, h6 {
      color: #05409e;
      font-family: sans-serif;
    }
  }

  .main{
    h1, h2, h3, h4, h5, h6, p {
        color: #05409e;
        font-family: sans-serif;
        align-content: center;
        text-align: center;
    }
  }

  .field {
    width: 100%;
    height: 56px;
    border-radius: 4px;
    position: relative;
    background-color: rgba(255,255,255,0.3);
    transition: 0.3s all;
  }
  .field:hover {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }